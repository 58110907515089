<template>
  <div class="games">
    <div class="title_en">OUR GAMES</div>
    <div class="title_zh">我们的游戏</div>
    <div class="games_img">
      <div class="game1">
        <img class="game_bg" src="@/assets/image/pc/home/hy.png" alt="">
         <a href="https://hy.gg.com/" target="_blank">
           <img class="enter_link" src="@/assets/image/pc/home/进入官网.png" alt="">
         </a>
        <!-- <a> -->
          <!-- <img class="enter_link" src="@/assets/image/pc/home/进入官网.png"> -->
          <!-- <div class="enter_link_button">
            <p>敬请期待</p>
          </div>
        </a> -->
        <!-- <img class="gamelogo" src="@/assets/image/pc/home/game3logo.png"> -->
      </div>
      <div class="game2">
        <img class="game_bg" src="@/assets/image/pc/home/game3.jpg">
        <a href="https://th.gg.com/" target="_blank"><img class="enter_link" src="@/assets/image/pc/home/进入官网.png"></a>
        <img class="gamelogo" src="@/assets/image/pc/home/game3logo.png">
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: "Games"
})
</script>

<style lang="scss" scoped>
.games {
  height: 760px;
  margin-top: 64px;
  .title_en {
    text-align: center;
    font-size: 80px;
    font-family: Poppins;
    font-weight: 800;
    color: #343434;
    line-height: 104px;
    opacity: 0.76;
  }
  .title_zh {
    text-align: center;
    font-size: 35px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #EB3644;
    line-height: 46px;
  }
  .games_img {
    margin-top: 45px;
    display: flex;
    .gamelogo{
      width: 219px;
      height: 98px;
    }
    .game_bg{
      width: 793px;
      height: 447px;
    }
    .game1{
      margin-left: 160px;
      img{
        position: absolute;
      }
       .enter_link{
         width: 184px;
         height: 45px;
         margin-top: 376px;
         margin-left: 38px;
         background: rgba(139, 109, 86, 0.5);
         // border: 1px solid #FFFFFF;
         border-radius: 22px;
         cursor: pointer;
       }
      .enter_link_button{
        position: absolute;
        width: 184px;
        height: 45px;
        margin-top: 376px;
        margin-left: 38px;
        background: rgba(139, 109, 86, 0.5);
        border: 1px solid #FFFFFF;
        border-radius: 22px;
        cursor: pointer;
        opacity: 0.75;
        p{
          text-align: center;
          // width: 97px;
          margin-top: 4px;
          height: 25px;
          font-size: 25px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FDFEFA;
          cursor: pointer;
        }
      }
    }
    .game2{
      margin-left: 804px;
      img{
        position: absolute;
      }
      .enter_link{
        cursor: pointer;
        width: 184px;
        height: 45px;
        margin-top: 376px;
        margin-left: 38px;
        background: rgba(139, 109, 86, 0.5);
        // border: 1px solid #FFFFFF;
        border-radius: 22px;
      }
    }
  }
}
</style>
